<template>
  <div :class="['cal-item', eventType]">
    <span>
      {{ product.title }}
      <span v-if="product.type_text !== '其他'">-</span>
      <span v-if="product.type_text !== '其他'">
        {{ product.type_text }}
      </span>
    </span>
  </div>
</template>

<script>
const moment = require('moment');

export default {
  props: {
    product: {
      required: true,
      type: Object
    }
  },
  data() {
    return {};
  },
  computed: {
    eventType() {
      if (this.product.type_text == '我的開團') {
        return 'group';
      } else if (this.product.type_text == '我的跟團') {
        return 'follow';
      } else {
        return 'others';
      }
    }
  },
  created() {},
  methods: {}
};
</script>

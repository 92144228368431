<template>
  <router-link
    class="cal-row"
    :to="{ name: 'MemberCalendarDetail', params: { time: singleInfo.date } }"
    :date="index + 1"
  >
    <div class="cal-content">
      <OneEvent
        v-for="(product, index) in singleInfo.events"
        :product="product"
        :key="index"
      />
    </div>
  </router-link>
</template>

<script>
const moment = require('moment');
import OneEvent from './one-event';

export default {
  components: {
    OneEvent
  },
  props: {
    singleInfo: {
      required: true,
      type: Object
    },
    index: {
      required: true,
      type: Number
    }
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {}
};
</script>

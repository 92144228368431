<template>
  <div class="main-content">
    <div class="cal-top control">
      <router-link
        :to="{
          name: 'MemberCalendar',
          params: { time: prevMonth }
        }"
        class="gonav go-prev"
      ></router-link>
      <div class="month current-month">
        {{ theTime }}
      </div>
      <router-link
        :to="{
          name: 'MemberCalendar',
          params: { time: nextMonth }
        }"
        class="gonav go-next"
      ></router-link>
    </div>
    <div class="cal-samples">
      <div class="sample group">我的開團</div>
      <div class="sample follow">我的跟團</div>
      <div class="sample others">其他</div>
    </div>
    <div class="cal-date-list">
      <OneDayInfo
        v-for="(singleInfo, index) in calendarList"
        :singleInfo="singleInfo"
        :key="index"
        :index="index"
      />
    </div>
  </div>
</template>

<script>
import { getMonthEvents } from '@/api/events';
import OneDayInfo from './one-day-info';

const moment = require('moment');
// @ is an alias to /src

export default {
  components: {
    OneDayInfo
  },
  data() {
    return {
      theTime: this.$route.params.time,
      calendarList: []
    };
  },
  computed: {
    calendarEventList() {
      let arr = this.calendarList.filter(item => {
        return item.events.length > 0;
      });
      return arr;
    },

    nextMonth() {
      let newMonth = moment(this.theTime).add(1, 'M');
      return newMonth.format('YYYY-MM');
    },

    prevMonth() {
      let newMonth = moment(this.theTime).subtract(1, 'M');
      return newMonth.format('YYYY-MM');
    }
  },
  created() {
    this.getTheMonthEvents();
  },
  watch: {
    $route() {
      this.theTime = this.$route.params.time;
      this.getTheMonthEvents();
    }
  },
  methods: {
    getTheMonthEvents() {
      let arr = this.theTime.split('-');

      let data = {
        year: arr[0],
        month: arr[1]
      };

      getMonthEvents(data).then(res => {
        this.calendarList = res;
      });
    }
  }
};
</script>
